import React from "react";
import "./terms.css";

const Terms = () => {
  return (
    <div className="terms-container">
      <div style={{ "margin-top": "50px" }}>
        <div className="container">
          <h4 style={{ "text-align": "center" }}>Farm2Bag Terms of Use</h4>
          <p>
            This document is an electronic record in terms of Information
            Technology Act, 2000 and rules there under as applicable and the
            amended provisions pertaining to electronic records in various
            statutes as amended by the Information Technology Act, 2000. This
            electronic record is generated by a computer system and does not
            require any physical or digital signatures. This document is
            published in accordance with the provisions of Rule 3 (1) of the
            Information Technology (Intermediaries guidelines) Rules, 2011 that
            require publishing the rules and regulations, privacy policy and
            Terms of Use for access or usage of domain name
            [www.Farm2Bag.com][www.Farm2Bag.com] (“Website”), including the
            related mobile site and mobile application (hereinafter referred to
            as “Platform”)
          </p>
          <p>
            The Platform is owned by Shinelogics Infotech Private Limited a
            company incorporated under the Companies Act, 1956 with its
            registered office at 3rd Floor, KJ Aditya Towers #L-14,Vikaram
            Sarabhai Instronic Estate Phase II, (SRP Tools), Thiruvanmiyur,
            Chennai – 600 042,Tamilnadu, India (hereinafter referred to as
            "Farm2Bag"). Your use of the Platform and services and tools are
            governed by the following terms and conditions ("Terms of Use") as
            applicable to the Platform including the applicable policies which
            are incorporated herein by way of reference. If You transact on the
            Platform, You shall be subject to the policies that are applicable
            to the Platform for such transaction. By mere use of the Platform,
            You shall be contracting with Shinelogics Infotech Private Limited
            and these terms and conditions including the policies constitute
            Your binding obligations, with Farm2Bag. For the purpose of these
            Terms of Use, wherever the context so requires "You" or "User" shall
            mean any natural or legal person who has agreed to become a buyer on
            the Platform by providing Registration Data while registering on the
            Platform as Registered User using the computer systems. Farm2Bag
            allows the User to surf the Platform or making purchases without
            registering on the Platform. The term "We", "Us", "Our" shall mean
            Shinelogics Infotech Private Limited.
          </p>
          <p>
            When You use any of the services provided by Us through the
            Platform, including but not limited to, (e.g. Product Reviews,
            Seller Reviews), You will be subject to the rules, guidelines,
            policies, terms, and conditions applicable to such service, and they
            shall be deemed to be incorporated into this Terms of Use and shall
            be considered as part and parcel of this Terms of Use. We reserve
            the right, at Our sole discretion, to change, modify, add or remove
            portions of these Terms of Use, at any time without any prior
            written notice to You. It is Your responsibility to review these
            Terms of Use periodically for updates / changes. Your continued use
            of the Platform following the posting of changes will mean that You
            accept and agree to the revisions. As long as You comply with these
            Terms of Use, We grant You a personal, non-exclusive,
            non-transferable, limited privilege to enter and use the Platform.
            ACCESSING, BROWSING OR OTHERWISE USING THE SITE INDICATES YOUR
            AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THESE TERMS OF USE,
            SO PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING. By
            impliedly or expressly accepting these Terms of Use, You also accept
            and agree to be bound by Farm2Bag Policies ((including but not
            limited to Privacy Policy available at Privacy) as amended from time
            to time.
          </p>
          <h4> Membership Eligibility</h4>

          <p>
            Transaction on the Platform is available only to persons who can
            form legally binding contracts under Indian Contract Act, 1872.
            Persons who are "incompetent to contract" within the meaning of the
            Indian Contract Act, 1872 including un-discharged insolvents etc.
            are not eligible to use the Platform. If you are a minor i.e. under
            the age of 18 years, you may use the Platform or access content on
            the Platform only under the supervision and prior consent/
            permission of a parent /legal guardian.
          </p>
          <p>
            As a minor if you wish to transact on the Platform, such transaction
            on the Platform may be made by your legal guardian or parents.
            Farm2Bag reserves the right to terminate your membership and / or
            refuse to provide you with access to the Platform if it is brought
            to Farm2Bag's notice or if it is discovered that You are under the
            age of 18 years and transacting on the Platform. Your Account and
            Registration Obligations
          </p>
          <p>
            If You use the Platform, You shall be responsible for maintaining
            the confidentiality of your Display Name and Password and You shall
            be responsible for all activities that occur under your Display Name
            and Password. You agree that if You provide any information that is
            untrue, inaccurate, not current or incomplete or We have reasonable
            grounds to suspect that such information is untrue, inaccurate, not
            current or incomplete, or not in accordance with the this Terms of
            Use, We shall have the right to indefinitely suspend or terminate or
            block access of your membership on the Platform and refuse to
            provide You with access to the Platform.
          </p>
          <p>
            Your mobile phone number and/or e-mail address is treated as Your
            primary identifier on the Platform. It is your responsibility to
            ensure that Your mobile phone number and your email address is up to
            date on the Platform at all times. You agree to notify Us promptly
            if your mobile phone number or e-mail address changes by updating
            the same on the Platform through a onetime password verification.
          </p>
          <p>
            You agree that Farm2Bag shall not be liable or responsible for the
            activities or consequences of use or misuse of any information that
            occurs under your Account in cases, including, where You have failed
            to update Your revised mobile phone number and/or e-mail address on
            the Website Platform
          </p>
          <p>
            If You share or allow others to have access to Your account on the
            Platform (“Account”), by creating separate profiles under Your
            Account, or otherwise, they will be able to view and access Your
            Account information. You shall be solely liable and responsible for
            all the activities undertaken under Your Account, and any
            consequences therefrom.
          </p>
          <h4>Communications</h4>
          <p>
            When You use the Platform or send emails or other data, information
            or communication to us, You agree and understand that You are
            communicating with Us through electronic records and You consent to
            receive communications via electronic records from Us periodically
            and as and when required. We may communicate with you by email or by
            such other mode of communication, electronic or otherwise.
          </p>
          <h4>Platform for Transaction and Communication</h4>
          <p>
            The Platform is a platform that Users utilize to meet and interact
            with one another for their transactions. Farm2Bag is not and cannot
            be a party to or control in any manner any transaction between the
            Platform's Users.
          </p>
          <p>Henceforward:</p>
          <p>
            1. All commercial/contractual terms are offered by and agreed to
            between Buyers and Sellers alone. The commercial/contractual terms
            include without limitation price, shipping costs, payment methods,
            payment terms, date, period and mode of delivery, warranties related
            to products and services and after sales services related to
            products and services. Farm2Bag does not have any control or does
            not determine or advise or in any way involve itself in the offering
            or acceptance of such commercial/contractual terms between the
            Buyers and Sellers. All discounts, offers (including exchange
            offers) are by the Seller/Brand and not by Farm2Bag.
          </p>
          <p>
            2. Placement of order by a Buyer with Seller on the Platform is an
            offer to buy the product(s) in the order by the Buyer to the Seller
            and it shall not be construed as Seller's acceptance of Buyer's
            offer to buy the product(s) ordered. The Seller retains the right to
            cancel any such order placed by the Buyer, at its sole discretion
            and the Buyer shall be intimated of the same by way of an email/SMS.
            Any transaction price paid by Buyer in case of such cancellation by
            Seller, shall be refunded to the Buyer. Further, the Seller may
            cancel an order wherein the quantities exceed the typical individual
            consumption. This applies both to the number of products ordered
            within a single order and the placing of several orders for the same
            product where the individual orders comprise a quantity that exceeds
            the typical individual consumption. What comprises a typical
            individual's consumption quantity limit shall be based on various
            factors and at the sole discretion of the Seller and may vary from
            individual to individual.
          </p>
          <p>
            3. Farm2Bag does not make any representation or Warranty as to
            specifics (such as quality, value, saleability, etc) of the products
            or services proposed to be sold or offered to be sold or purchased
            on the Platform. Farm2Bag does not implicitly or explicitly support
            or endorse the sale or purchase of any products or services on the
            Platform. Farm2Bag accepts no liability for any errors or omissions,
            whether on behalf of itself or third parties.
          </p>
          <p>
            4. Farm2Bag is not responsible for any non-performance or breach of
            any contract entered into between Buyers and Sellers. Farm2Bag
            cannot and does not guarantee that the concerned Buyers and/or
            Sellers will perform any transaction concluded on the Platform.
            Farm2Bag shall not and is not required to mediate or resolve any
            dispute or disagreement between Buyers and Sellers.
          </p>
          <p>
            5. Farm2Bag does not make any representation or warranty as to the
            item-specifics (such as legal title, creditworthiness, identity,
            etc) of any of its Users. You are advised to independently verify
            the bona fides of any particular User that You choose to deal with
            on the Platform and use Your best judgment in that behalf.
          </p>
          <p>
            6. Farm2Bag does not at any point of time during any transaction
            between Buyer and Seller on the Platform come into or take
            possession of any of the products or services offered by Seller nor
            does it at any point gain title to or have any rights or claims over
            the products or services offered by Seller to Buyer.
          </p>
          <p>
            7. At no time shall Farm2Bag hold any right, title or interest over
            the products nor shall Farm2Bag have any obligations or liabilities
            in respect of such contract entered into between Buyers and Sellers.
            Farm2Bag is not responsible for unsatisfactory or delayed
            performance of services or damages or delays as a result of products
            which are out of stock, unavailable or back ordered.
          </p>
          <p>
            8. The Platform is only a platform that can be utilized by Users to
            reach a larger base to buy and sell products or services. Farm2Bag
            is only providing a platform for communication and it is agreed that
            the contract for sale of any of the products or services shall be a
            strictly bipartite contract between the Seller and the
            Buyer.Farm2Bag, currently, does not facilitate business to business
            transaction between Sellers and business customers. You are advised
            to refrain from transacting on the Platform if You intend to avail
            the benefits of input tax credit.
          </p>
          <p>
            At no time shall Farm2Bag hold any right, title or interest over the
            products nor shall Farm2Bag have any obligations or liabilities in
            respect of such contract.
          </p>
          <p>
            Farm2Bag is not responsible for unsatisfactory or delayed
            performance of services or damages or delays as a result of products
            which are out of stock, unavailable or back ordered.
          </p>
          <p>
            1. You shall independently agree upon the manner and terms and
            conditions of delivery, payment, insurance etc. with the seller(s)
            that You transact with.
          </p>
          <p>
            Disclaimer: Pricing on any product(s) as is reflected on the
            Platform may due to some technical issue, typographical error or
            product information published by seller may be incorrectly reflected
            and in such an event seller may cancel such your order(s).
          </p>
          <p>
            1. You release and indemnify Farm2Bag and/or any of its officers and
            representatives from any cost, damage, liability or other
            consequence of any of the actions of the Users of the Platform and
            specifically waive any claims that you may have in this behalf under
            any applicable law. Notwithstanding its reasonable efforts in that
            behalf, Farm2Bag cannot take responsibility or control the
            information provided by other Users which is made available on the
            Platform. You may find other User's information to be offensive,
            harmful, inconsistent, inaccurate, or deceptive. Please use caution
            and practice safe trading when using the Platform. Please note that
            there could be risks in dealing with underage persons or people
            acting under false pretence.
          </p>
          <h4>Charges</h4>
          <p>
            Membership on the Platform is free for buyers. Farm2Bag does not
            charge any fee for browsing and buying on the Platform. Farm2Bag
            reserves the right to change its Fee Policy from time to time. In
            particular, Farm2Bag may at its sole discretion introduce new
            services and modify some or all of the existing services offered on
            the Platform. In such an event Farm2Bag reserves the right to
            introduce fees for the new services offered or amend/introduce fees
            for existing services, as the case may be. Changes to the Fee Policy
            shall be posted on the Platform and such changes shall automatically
            become effective immediately after they are posted on the Platform.
            Unless otherwise stated, all fees shall be quoted in Indian Rupees.
            You shall be solely responsible for compliance of all applicable
            laws including those in India for making payments to Farm2Bag
            Internet Private Limited.
          </p>
          <p>
            You agree, undertake and confirm that Your use of Platform shall be
            strictly governed by the following binding principles:
          </p>
          <p>
            1. You shall not host, display, upload, modify, publish, transmit,
            update or share any information which:
          </p>

          <p>
            (a) belongs to another person and to which You does not have any
            right to;
          </p>
          <p>
            (b) is grossly harmful, harassing, blasphemous, defamatory, obscene,
            pornographic, paedophilic, libellous, invasive of another's privacy,
            hateful, or racially, ethnically objectionable, disparaging,
            relating or encouraging money laundering or gambling, or otherwise
            unlawful in any manner whatever; or unlawfully threatening or
            unlawfully harassing including but not limited to "indecent
            representation of women" within the meaning of the Indecent
            Representation of Women (Prohibition) Act, 1986;
          </p>
          <p>(c) is misleading in any way;</p>
          <p>
            (d) is patently offensive to the online community, such as sexually
            explicit content, or content that promotes obscenity, paedophilia,
            racism, bigotry, hatred or physical harm of any kind against any
            group or individual;
          </p>
          <p>(e) harasses or advocates harassment of another person;</p>
          <p>
            (f) involves the transmission of "junk mail", "chain letters", or
            unsolicited mass mailing or "spamming";
          </p>
          <p>
            (g) promotes illegal activities or conduct that is abusive,
            threatening, obscene, defamatory or libellous;
          </p>
          <p>
            (h) infringes upon or violates any third party's rights [including,
            but not limited to, intellectual property rights, rights of privacy
            (including without limitation unauthorized disclosure of a person's
            name, email address, physical address or phone number) or rights of
            publicity];
          </p>
          <p>
            (i) promotes an illegal or unauthorized copy of another person's
            copyrighted work (see "Copyright complaint" below for instructions
            on how to lodge a complaint about uploaded copyrighted material),
            such as providing pirated computer programs or links to them,
            providing information to circumvent manufacture-installed
            copy-protect devices, or providing pirated music or links to pirated
            music files;
          </p>
          <p>
            (j) contains restricted or password-only access pages, or hidden
            pages or images (those not linked to or from another accessible
            page);
          </p>
          <p>
            (k) provides material that exploits people in a sexual, violent or
            otherwise inappropriate manner or solicits personal information from
            anyone;
          </p>
          <p>
            (l) provides instructional information about illegal activities such
            as making or buying illegal weapons, violating someone's privacy, or
            providing or creating computer viruses;
          </p>
          <p>
            (m) contains video, photographs, or images of another person (with a
            minor or an adult).
          </p>
          <p>
            (n) tries to gain unauthorized access or exceeds the scope of
            authorized access to the Platform or to profiles, blogs,
            communities, account information, bulletins, friend request, or
            other areas of the Platform or solicits passwords or personal
            identifying information for commercial or unlawful purposes from
            other users;
          </p>
          <p>
            (o) engages in commercial activities and/or sales without Our prior
            written consent such as contests, sweepstakes, barter, advertising
            and pyramid schemes, or the buying or selling of "virtual" products
            related to the Platform. Throughout this Terms of Use, Farm2Bag's
            prior written consent means a communication coming from Farm2Bag's
            Legal Department, specifically in response to Your request, and
            specifically addressing the activity or conduct for which You seek
            authorization;
          </p>
          <p>
            (p) solicits gambling or engages in any gambling activity which We,
            in Our sole discretion, believes is or could be construed as being
            illegal;
          </p>
          <p>
            (q) interferes with another USER's use and enjoyment of the Platform
            or any other individual's User and enjoyment of similar services;
          </p>
          <p>
            (r) refers to any Platform or URL that, in Our sole discretion,
            contains material that is inappropriate for the Platform or any
            other Platform, contains content that would be prohibited or
            violates the letter or spirit of these Terms of Use.
          </p>
          <p>(s) harm minors in any way;</p>
          <p>
            (t) infringes any patent, trademark, copyright or other proprietary
            rights or third party's trade secrets or rights of publicity or
            privacy or shall not be fraudulent or involve the sale of
            counterfeit or stolen products;
          </p>
          <p>(u) violates any law for the time being in force;</p>
          <p>
            (v) deceives or misleads the addressee/ users about the origin of
            such messages or communicates any information which is grossly
            offensive or menacing in nature;
          </p>
          <p>(w) impersonate another person;</p>
          <p>
            (x) contains software viruses or any other computer code, files or
            programs designed to interrupt, destroy or limit the functionality
            of any computer resource; or contains any trojan horses, worms, time
            bombs, cancelbots, easter eggs or other computer programming
            routines that may damage, detrimentally interfere with, diminish
            value of, surreptitiously intercept or expropriate any system, data
            or personal information;
          </p>
          <p>
            (y) threatens the unity, integrity, defence, security or sovereignty
            of India, friendly relations with foreign states, or public order or
            causes incitement to the commission of any cognizable offence or
            prevents investigation of any offence or is insulting any other
            nation.
          </p>
          <p>(z) shall not be false, inaccurate or misleading;</p>
          <p>
            (aa) shall not, directly or indirectly, offer, attempt to offer,
            trade or attempt to trade in any item, the dealing of which is
            prohibited or restricted in any manner under the provisions of any
            applicable law, rule, regulation or guideline for the time being in
            force.
          </p>
          <p>
            (ab) shall not create liability for Us or cause Us to lose (in whole
            or in part) the services of Our internet service provider ("ISPs")
            or other suppliers;
          </p>
          <p>
            1. You shall not use any "deep-link", "page-scrape", "robot",
            "spider" or other automatic device, program, algorithm or
            methodology, or any similar or equivalent manual process, to access,
            acquire, copy or monitor any portion of the Platform or any Content,
            or in any way reproduce or circumvent the navigational structure or
            presentation of the Platform or any Content, to obtain or attempt to
            obtain any materials, documents or information through any means not
            purposely made available through the Platform. We reserve Our right
            to bar any such activity.
          </p>
          <p>
            2. You shall not attempt to gain unauthorized access to any portion
            or feature of the Platform, or any other systems or networks
            connected to the Platform or to any server, computer, network, or to
            any of the services offered on or through the Platform, by hacking,
            password "mining" or any other illegitimate means.
          </p>
          <p>
            3. You shall not probe, scan or test the vulnerability of the
            Platform or any network connected to the Platform nor breach the
            security or authentication measures on the Platform or any network
            connected to the Platform. You may not reverse look-up, trace or
            seek to trace any information on any other User of or visitor to
            Platform, or any other customer, including any account on the
            Platform not owned by You, to its source, or exploit the Platform or
            any service or information made available or offered by or through
            the Platform, in any way where the purpose is to reveal any
            information, including but not limited to personal identification or
            information, other than Your own information, as provided for by the
            Platform.
          </p>
          <p>
            4. You shall not make any negative, denigrating or defamatory
            statement(s) or comment(s) about Us or the brand name or domain name
            used by Us including the terms Farm2Bag, Flyte, Digiflip, Flipcart,
            Farm2Bag.com, or otherwise engage in any conduct or action that
            might tarnish the image or reputation, of Farm2Bag or sellers on
            platform or otherwise tarnish or dilute any Farm2Bag's trade or
            service marks, trade name and/or goodwill associated with such trade
            or service marks, trade name as may be owned or used by us. You
            agree that You will not take any action that imposes an unreasonable
            or disproportionately large load on the infrastructure of the
            Platform or Farm2Bag's systems or networks, or any systems or
            networks connected to Farm2Bag.
          </p>
          <p>
            5. You agree not to use any device, software or routine to interfere
            or attempt to interfere with the proper working of the Platform or
            any transaction being conducted on the Platform, or with any other
            person's use of the Platform.
          </p>
          <p>
            6. You may not forge headers or otherwise manipulate identifiers in
            order to disguise the origin of any message or transmittal You send
            to Us on or through the Platform or any service offered on or
            through the Platform. You may not pretend that You are, or that You
            represent, someone else, or impersonate any other individual or
            entity.
          </p>
          <p>
            7. You may not use the Platform or any content for any purpose that
            is unlawful or prohibited by these Terms of Use, or to solicit the
            performance of any illegal activity or other activity which
            infringes the rights of Farm2Bag and / or others.
          </p>
          <p>
            8. You shall at all times ensure full compliance with the applicable
            provisions of the Information Technology Act, 2000 and rules
            thereunder as applicable and as amended from time to time and also
            all applicable Domestic laws, rules and regulations (including the
            provisions of any applicable Exchange Control Laws or Regulations in
            Force) and International Laws, Foreign Exchange Laws, Statutes,
            Ordinances and Regulations (including, but not limited to Sales
            Tax/VAT, Income Tax, Octroi, Service Tax, Central Excise, Custom
            Duty, Local Levies) regarding Your use of Our service and Your
            listing, purchase, solicitation of offers to purchase, and sale of
            products or services. You shall not engage in any transaction in an
            item or service, which is prohibited by the provisions of any
            applicable law including exchange control laws or regulations for
            the time being in force.
          </p>
          <p>
            9. Solely to enable Us to use the information You supply Us with, so
            that we are not violating any rights You might have in Your
            Information, You agree to grant Us a non-exclusive, worldwide,
            perpetual, irrevocable, royalty-free, sub-licensable (through
            multiple tiers) right to exercise the copyright, publicity, database
            rights or any other rights You have in Your Information, in any
            media now known or not currently known, with respect to Your
            Information. We will only use Your information in accordance with
            the Terms of Use and Privacy Policy applicable to use of the
            Platform.
          </p>
          <p>
            10. From time to time, You shall be responsible for providing
            information relating to the products or services proposed to be sold
            by You. In this connection, You undertake that all such information
            shall be accurate in all respects. You shall not exaggerate or over
            emphasize the attributes of such products or services so as to
            mislead other Users in any manner.
          </p>
          <p>
            11. You shall not engage in advertising to, or solicitation of,
            other Users of the Platform to buy or sell any products or services,
            including, but not limited to, products or services related to that
            being displayed on the Platform or related to us. You may not
            transmit any chain letters or unsolicited commercial or junk email
            to other Users via the Platform. It shall be a violation of these
            Terms of Use to use any information obtained from the Platform in
            order to harass, abuse, or harm another person, or in order to
            contact, advertise to, solicit, or sell to another person other than
            Us without Our prior explicit consent. In order to protect Our Users
            from such advertising or solicitation, We reserve the right to
            restrict the number of messages or emails which a user may send to
            other Users in any 24-hour period which We deems appropriate in its
            sole discretion. You understand that We have the right at all times
            to disclose any information (including the identity of the persons
            providing information or materials on the Platform) as necessary to
            satisfy any law, regulation or valid governmental request. This may
            include, without limitation, disclosure of the information in
            connection with investigation of alleged illegal activity or
            solicitation of illegal activity or in response to a lawful court
            order or subpoena. In addition, We can (and You hereby expressly
            authorize Us to) disclose any information about You to law
            enforcement or other government officials, as we, in Our sole
            discretion, believe necessary or appropriate in connection with the
            investigation and/or resolution of possible crimes, especially those
            that may involve personal injury.
          </p>
          <p>
            We reserve the right, but has no obligation, to monitor the
            materials posted on the Platform. Farm2Bag shall have the right to
            remove or edit any content that in its sole discretion violates, or
            is alleged to violate, any applicable law or either the spirit or
            letter of these Terms of Use. Notwithstanding this right, YOU REMAIN
            SOLELY RESPONSIBLE FOR THE CONTENT OF THE MATERIALS YOU POST ON THE
            PLATFORM AND IN YOUR PRIVATE MESSAGES. Please be advised that such
            Content posted does not necessarily reflect Farm2Bag views. In no
            event shall Farm2Bag assume or have any responsibility or liability
            for any Content posted or for any claims, damages or losses
            resulting from use of Content and/or appearance of Content on the
            Platform. You hereby represent and warrant that You have all
            necessary rights in and to all Content which You provide and all
            information it contains and that such Content shall not infringe any
            proprietary or other rights of third parties or contain any
            libellous, tortious, or otherwise unlawful information.
          </p>
          <p>
            1. Your correspondence or business dealings with, or participation
            in promotions of, advertisers found on or through the Platform,
            including payment and delivery of related products or services, and
            any other terms, conditions, warranties or representations
            associated with such dealings, are solely between You and such
            advertiser. We shall not be responsible or liable for any loss or
            damage of any sort incurred as the result of any such dealings or as
            the result of the presence of such advertisers on the Platform.
          </p>
          <p>
            2. It is possible that other users (including unauthorized users or
            "hackers") may post or transmit offensive or obscene materials on
            the Platform and that You may be involuntarily exposed to such
            offensive and obscene materials. It also is possible for others to
            obtain personal information about You due to your use of the
            Platform, and that the recipient may use such information to harass
            or injure You. We does not approve of such unauthorized uses, but by
            using the Platform You acknowledge and agree that We are not
            responsible for the use of any personal information that You
            publicly disclose or share with others on the Platform. Please
            carefully select the type of information that You publicly disclose
            or share with others on the Platform.
          </p>
          <p>
            3. Farm2Bag shall have all the rights to take necessary action and
            claim damages that may occur due to your involvement/participation
            in any way on your own or through group/s of people, intentionally
            or unintentionally in DoS/DDoS (Distributed Denial of Services).
          </p>
          <p>
            4. If you choose to browse or transact on the Platform using the
            voice command-based shopping feature, you acknowledge that Farm2Bag
            and/or its affiliates will collect, store and use your voice inputs
            on this feature to customize your experience and provide additional
            functionality as well as to improve Farm2Bag’s and/or its
            affiliates’ products and services. Farm2Bag’s and/or its affiliates’
            use of your voice data will be in accordance with the Farm2Bag
            Privacy Policy. You accept that your use of this feature is
            restricted to the territory of the Republic of India. This feature
            may not be accessible on certain devices and requires an internet
            connection. Farm2Bag reserves the right to change, enhance, suspend,
            or discontinue this feature, or any part of it, at any time without
            notice to you. Your continued use of this feature constitutes your
            acceptance of the terms related to this feature.
          </p>
          <p>
            5. You acknowledge and agree that Hindi translation feature is made
            available on the Website on a reasonable effort basis for
            convenience only, without any representation or warranties by
            Farm2Bag, express or implied, including it being error free, its
            accuracy, completeness or reliability. Farm2Bag expressly disclaims
            any liability of any nature whatsoever arising from or related to
            the said Hindi translation feature. Some features and sections on
            the Website may not be translated in Hindi language, and the English
            version of the same will be the definitive version. In the event of
            any conflict or inconsistency between the translated terms and the
            English version available on the Website, the English version on the
            Website shall prevail. This feature may not be accessible on certain
            devices. Farm2Bag reserves the right to change, enhance, suspend, or
            discontinue this feature, or any part of it, at any time without
            notice to You.
          </p>
          <h4>Contents Posted on Platform</h4>
          <p>
            All text, graphics, user interfaces, visual interfaces, photographs,
            trademarks, logos, sounds, music and artwork (collectively,
            "Content"), is a third party user generated content and Farm2Bag has
            no control over such third party user generated content as Farm2Bag
            is merely an intermediary for the purposes of this Terms of Use.
          </p>
          <p>
            Except as expressly provided in these Terms of Use, no part of the
            Platform and no Content may be copied, reproduced, republished,
            uploaded, posted, publicly displayed, encoded, translated,
            transmitted or distributed in any way (including "mirroring") to any
            other computer, server, Platform or other medium for publication or
            distribution or for any commercial enterprise, without Farm2Bag's
            express prior written consent.
          </p>
          <p>
            You may use information on the products and services purposely made
            available on the Platform for downloading, provided that You (1) do
            not remove any proprietary notice language in all copies of such
            documents, (2) use such information only for your personal,
            non-commercial informational purpose and do not copy or post such
            information on any networked computer or broadcast it in any media,
            (3) make no modifications to any such information, and (4) do not
            make any additional representations or warranties relating to such
            documents.
          </p>
          <p>
            You shall be responsible for any notes, messages, emails, reviews,
            ratings, billboard postings, photos, drawings, profiles, opinions,
            ideas, images, videos, audio files or other materials or information
            posted or transmitted to the Platform (collectively, "Content").
            Such Content will become Our property and You grant Us the
            worldwide, perpetual and transferable rights in such Content. We
            shall be entitled to, consistent with Our Privacy Policy as adopted
            in accordance with applicable law, use the Content or any of its
            elements for any type of use forever, including but not limited to
            promotional and advertising purposes and in any media whether now
            known or hereafter devised, including the creation of derivative
            works that may include the Content You provide. You agree that any
            Content You post may be used by us, consistent with Our Privacy
            Policy and Rules of Conduct on Site as mentioned herein, and You are
            not entitled to any payment or other compensation for such use.
          </p>
          <p>
            SOME CONTENT OFFERED ON THE PLATFORM MAY NOT BE SUITABLE FOR SOME
            VIEWERS AND THEREFORE VIEWER DISCRETION IS ADVISED. ALSO, SOME
            CONTENT OFFERED ON THE PLATFORM MAY NOT BE APPROPRIATE FOR
            VIEWERSHIP BY CHILDREN. PARENTS AND/OR LEGAL GUARDIANS ARE ADVISED
            TO EXERCISE DISCRETION BEFORE ALLOWING THEIR CHILDREN AND/OR WARDS
            TO ACCESS CONTENT ON THE PLATFORM.
          </p>
          <h4>Payment</h4>
          <p>
            While availing any of the payment method/s available on the
            Platform, we will not be responsible or assume any liability,
            whatsoever in respect of any loss or damage arising directly or
            indirectly to You due to:
          </p>

          <p>
            1. Lack of authorization for any transaction/s, or 2. Exceeding the
            pre-set limit mutually agreed by You and between "Bank/s", or 3. Any
            payment issues arising out of the transaction, or 4. Decline of
            transaction for any other reason/s
          </p>

          <p>
            All payments made against the purchases/services on Platform by you
            shall be compulsorily in Indian Rupees acceptable in the Republic of
            India. Platform will not facilitate transaction with respect to any
            other form of currency with respect to the purchases made on
            Platform. Before shipping / delivering your order to you, Seller may
            request you to provide supporting documents (including but not
            limited to Govt. issued ID and address proof) to establish the
            ownership of the payment instrument used by you for your purchase.
            This is done in the interest of providing a safe online shopping
            environment to Our Users.
          </p>
          <h4>Further:</h4>
          <p>
            1. Transactions, Transaction Price and all commercial terms such as
            Delivery, Dispatch of products and/or services are as per principal
            to principal bipartite contractual obligations between Buyer and
            Seller and payment facility is merely used by the Buyer and Seller
            to facilitate the completion of the Transaction. Use of the payment
            facility shall not render Farm2Bag liable or responsible for the
            non-delivery, non-receipt, non-payment, damage, breach of
            representations and warranties, non-provision of after sales or
            warranty services or fraud as regards the products and /or services
            listed on Farm2Bag's Platform.
          </p>
          <p>
            2. You have specifically authorized Farm2Bag or its service
            providers to collect, process, facilitate and remit payments and /
            or the Transaction Price electronically or through Cash on Delivery
            to and from other Users in respect of transactions through Payment
            Facility. Your relationship with Farm2Bag is on a principal to
            principal basis and by accepting these Terms of Use you agree that
            Farm2Bag is an independent contractor for all purposes, and does not
            have control of or liability for the products or services that are
            listed on Farm2Bag's Platform that are paid for by using the Payment
            Facility. Farm2Bag does not guarantee the identity of any User nor
            does it ensure that a Buyer or a Seller will complete a transaction.
          </p>
          <p>
            3. You understand, accept and agree that the payment facility
            provided by Farm2Bag is neither a banking nor financial service but
            is merely a facilitator providing an electronic, automated online
            electronic payment, receiving payment through Cash On Delivery,
            collection and remittance facility for the Transactions on the
            Farm2Bag Platform using the existing authorized banking
            infrastructure and Credit Card payment gateway networks. Further, by
            providing Payment Facility, Farm2Bag is neither acting as trustees
            nor acting in a fiduciary capacity with respect to the Transaction
            or the Transaction Price.
          </p>
          <h4>Payment Facility for Buyers:</h4>
          <p>
            1. You, as a Buyer, understand that upon initiating a Transaction
            You are entering into a legally binding and enforceable contract
            with the Seller to purchase the products and /or services from the
            Seller using the Payment Facility, and You shall pay the Transaction
            Price through Your Issuing Bank to the Seller using Payment
            Facility.
          </p>
          <p>
            2. You, as a Buyer, may agree with the Seller through electronic
            communication and electronic records and using the automated
            features as may be provided by Payment Facility on any extension /
            increase in the Dispatch and/or Delivery time and the Transaction
            shall stand amended to such extent. Any such extension / increase of
            Dispatch / Delivery time or subsequent novation / variation of the
            Transaction should be in compliance with Payment Facility Rules and
            Policies.
          </p>
          <p>
            3. You, as a Buyer, shall electronically notify Payment Facility
            using the appropriate Farm2Bag Platform features immediately upon
            Delivery or non Delivery within the time period as provided in
            Policies. Non notification by You of Delivery or non Delivery within
            the time period specified in the Policies shall be construed as a
            deemed Delivery in respect of that Transaction. In case of Cash On
            Delivery transactions, Buyer is not required to confirm the receipt
            of products or services.
          </p>
          <p>
            4. You, as a Buyer, shall be entitled to claim a refund of the
            Transaction Price (as Your sole and exclusive remedy) in case You do
            not receive the Delivery within the time period agreed in the
            Transaction or within the time period as provided in the Policies,
            whichever is earlier. In case you do not raise a refund claim using
            Platform features within the stipulated time than this would make
            You ineligible for a refund.
          </p>
          <p>
            5. You, as a Buyer, understand that the Payment Facility may not be
            available in full or in part for certain category of products and/or
            services and/or Transactions as mentioned in the Policies and hence
            You may not be entitled to a refund in respect of the Transactions
            for those products and /or services
          </p>
          <p>
            6. Except for Cash On Delivery transaction, refund, if any, shall be
            made at the same Issuing Bank from where Transaction Price was
            received, or through any other method available on the Platform, as
            chosen by You.
          </p>
          <p>
            7. For Cash On Delivery transactions, refunds, if any, will be made
            via electronic payment transfers.
          </p>
          <p>
            8. Refund shall be made in Indian Rupees only and shall be
            equivalent to the Transaction Price received in Indian Rupees.
          </p>
          <p>
            9. For electronics payments, refund shall be made through payment
            facility using NEFT / RTGS or any other online banking / electronic
            funds transfer system approved by Reserve Bank India (RBI).
          </p>
          <p>
            10. Refunds may be supported for select banks. Where a bank is not
            supported for processing refunds, You will be required to share
            alternate bank account details with us for processing the refund.
          </p>
          <p>
            11. Refund shall be conditional and shall be with recourse available
            to Farm2Bag in case of any misuse by Buyer.
          </p>
          <p>
            12. We may also request you for additional documents for
            verification.
          </p>
          <p>13. Refund shall be subject to Buyer complying with Policies.</p>
          <p>
            14. Farm2Bag reserves the right to impose limits on the number of
            Transactions or Transaction Price which Farm2Bag may receive from on
            an individual Valid Credit/Debit/ Cash Card / Valid Bank Account/
            and such other infrastructure or any other financial instrument
            directly or indirectly through payment aggregator or through any
            such facility authorized by Reserve Bank of India to provide
            enabling support facility for collection and remittance of payment
            or by an individual Buyer during any time period, and reserves the
            right to refuse to process Transactions exceeding such limit.
          </p>
          <p>
            15. Farm2Bag reserves the right to refuse to process Transactions by
            Buyers with a prior history of questionable charges including
            without limitation breach of any agreements by Buyer with Farm2Bag
            or breach/violation of any law or any charges imposed by Issuing
            Bank or breach of any policy.
          </p>
          <p>
            16. Farm2Bag may do such checks as it deems fit before approving the
            receipt of/Buyers commitment to pay (for Cash On Delivery
            transactions) Transaction Price from the Buyer for security or other
            reasons at the discretion of Farm2Bag. As a result of such check if
            Farm2Bag is not satisfied with the creditability of the Buyer or
            genuineness of the Transaction or other reasons at its sole
            discretion, Farm2Bag shall have the right to reject the receipt of /
            Buyers commitment to pay Transaction Price. For avoidance of doubt,
            it is hereby clarified that the ‘Cash on Delivery’ feature for
            payment, may be disabled for certain account users, at the sole
            discretion of Farm2Bag.
          </p>
          <p>
            17. Farm2Bag may delay notifying the payment confirmation i.e.
            informing Seller to Dispatch, if Farm2Bag deems suspicious or for
            Buyers conducting high transaction volumes to ensure safety of the
            Transaction and Transaction Price. In addition, Farm2Bag may hold
            Transaction Price and Farm2Bag may not inform Seller to Dispatch or
            remit Transaction Price to law enforcement officials (instead of
            refunding the same to Buyer) at the request of law enforcement
            officials or in the event the Buyer is engaged in any form of
            illegal activity.
          </p>
          <p>
            18. The Buyer and Seller acknowledge that Farm2Bag will not be
            liable for any damages, interests or claims etc. resulting from not
            processing a Transaction/Transaction Price or any delay in
            processing a Transaction/Transaction Price which is beyond control
            of Farm2Bag.
          </p>
          <h4>Compliance with Laws:</h4>
          <p>
            1. As required by applicable law, if the Customer makes a purchase
            of an amount equal to or above INR 2 00 000.00, the Customer will be
            required to upload a scanned copy of his/her PAN card on the
            Platform, within 4 days of making the purchase, failing which, the
            purchase made by the Customer will be cancelled. The requirement to
            submit the PAN card arises only once and if it has been submitted
            once by the Customer, it need not be submitted again. The order of
            the Customer shall stand cancelled if there is a discrepancy between
            the name of the Customer and the name on the PAN Card.
          </p>
          <p>
            2. Buyer and Seller shall comply with all the applicable laws
            (including without limitation Foreign Exchange Management Act, 1999
            and the rules made and notifications issued there under and the
            Exchange Control Manual as may be issued by Reserve Bank of India
            from time to time, Customs Act, Information and Technology Act, 2000
            as amended by the Information Technology (Amendment) Act 2008,
            Prevention of Money Laundering Act, 2002 and the rules made there
            under, Foreign Contribution Regulation Act, 1976 and the rules made
            there under, Income Tax Act, 1961 and the rules made there under,
            Export Import Policy of government of India) applicable to them
            respectively for using Payment Facility and Farm2Bag Platform.
          </p>
          <h4>Buyer's arrangement with Issuing Bank:</h4>
          <p>
            1. All Valid Credit / Debit/ Cash Card/ and other payment
            instruments are processed using a Credit Card payment gateway or
            appropriate payment system infrastructure and the same will also be
            governed by the terms and conditions agreed to between the Buyer and
            the respective Issuing Bank and payment instrument issuing company.
          </p>
          <p>
            2. All Online Bank Transfers from Valid Bank Accounts are processed
            using the gateway provided by the respective Issuing Bank which
            support Payment Facility to provide these services to the Users. All
            such Online Bank Transfers on Payment Facility are also governed by
            the terms and conditions agreed to between Buyer and the respective
            Issuing Bank.
          </p>
          <h4>Farm2Bag's Replacement Guarantee*</h4>
          <p>
            Farm2Bag's Replacement Guarantee seeks to assist Buyers who have
            been defrauded by qualified sellers on the Platform. The return
            policy period (Farm2Bag's Replacement Guarantee) depends on the
            product category and the seller. Kindly click here to know the
            return policy period applicable for different categories. If at the
            time of delivery and/or within the applicable return policy period,
            if any defect is found, then the buyer of the product/s can ask for
            replacement of the product/s from the seller subject to the
            following terms and conditions
          </p>
          <p>
            1. Notify seller of any defects in the product/s at the time of
            delivery of the product/s and/or within the applicable return policy
            period and the same product/s will be replaced in return of the
            defective product/s.
          </p>
          <p>
            2. Following products shall not be eligible for return or
            replacement:
          </p>

          <p>a. fruits and vegetables; b. Milk Products</p>

          <p>
            If Farm2Bag has any suspicion or knowledge that any of its buyers
            and sellers are involved in any activity that is intended to provide
            claims or information that is false or not genuine, Farm2Bag may
            also, while reserving its rights to initiate civil and/or criminal
            proceedings against such member buyers and sellers, at its sole
            discretion, suspend, block, restrict, cancel the Display Name of
            such buyers and sellers and/or disqualify that user and any related
            users from availing protection through this program. Customers who
            have been blocked for any suspicious or fraudulent activity on
            Farm2Bag will not be allowed to return their products.
          </p>
          <p>
            Farm2Bag reserves its right to initiate civil and/or criminal
            proceedings against a user who, files a invalid and/or false claims
            or provides false, incomplete, or misleading information. In
            addition to the legal proceedings as aforesaid, Farm2Bag may at its
            sole discretion suspend, block, restrict, cancel the Display Name
            [and its related Display Names] of such user and/or disqualify that
            user and any related users from availing protection through this
            program. Any person who, knowingly and with intent to injure,
            defraud or deceive, files a Fraudulent Complaint containing false,
            incomplete, or misleading information may be guilty of a criminal
            offence and will be prosecuted to the fullest extent of the law.
          </p>
          <h4>Indemnity</h4>
          <p>
            You shall indemnify and hold harmless Farm2Bag, its owner, licensee,
            affiliates, subsidiaries, group companies (as applicable) and their
            respective officers, directors, agents, and employees, from any
            claim or demand, or actions including reasonable attorneys' fees,
            made by any third party or penalty imposed due to or arising out of
            Your breach of this Terms of Use, privacy Policy and other Policies,
            or Your violation of any law, rules or regulations or the rights
            (including infringement of intellectual property rights) of a third
            party. Applicable Law
          </p>
          <p>
            Terms of Use shall be governed by and interpreted and construed in
            accordance with the laws of India. The place of jurisdiction shall
            be exclusively in Tamilnadu. Jurisdictional Issues/Sale in India
            Only
          </p>
          <p>
            Unless otherwise specified, the material on the Platform is
            presented solely for the purpose of sale in India. Farm2Bag make no
            representation that materials in the Platform are appropriate or
            available for use in other locations/Countries other than India.
            Those who choose to access this site from other locations/Countries
            other than India do so on their own initiative and Farm2Bag is not
            responsible for supply of products/refund for the products ordered
            from other locations/Countries other than India, compliance with
            local laws, if and to the extent local laws are applicable.
          </p>
          <h4>Copyright and Restriction</h4>
          <p>
            This site is controlled and operated by Farm2Bag and products are
            sold by respective Sellers. All material on this site, including
            images, illustrations, audio clips, and video clips, are protected
            by copyrights, and other intellectual property rights. Material on
            Platform is solely for Your personal, non-commercial use. You must
            not copy, reproduce, republish, upload, post, transmit or distribute
            such material in any way, including by email or other electronic
            means and whether directly or indirectly and You must not assist any
            other person to do so. Without the prior written consent of the
            owner, modification of the materials, use of the materials on any
            other Platform or networked computer environment or use of the
            materials for any purpose other than personal, non-commercial use is
            a violation of the copyrights, trademarks and other proprietary
            rights, and is prohibited. Any use for which You receive any
            remuneration, whether in money or otherwise, is a commercial use for
            the purposes of this clause.
          </p>
          <h4>Limitation of Liability</h4>
          <p>
            IN NO EVENT SHALL FARM2BAG BE LIABLE FOR ANY SPECIAL, INCIDENTAL,
            INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND IN CONNECTION WITH
            THESE TERMS OF USE, EVEN IF USER HAS BEEN INFORMED IN ADVANCE OF THE
            POSSIBILITY OF SUCH DAMAGES.
          </p>
          <p>
            Please [contact us][] for any questions or comments (including all
            inquiries unrelated to copyright infringement) regarding this
            Platform.
          </p>
          <p>Grievance officer </p>
          <p>
            In accordance with Information Technology Act 2000 and rules made
            there under, the name and contact details of the Grievance Officer
            are provided below:
          </p>
          <p>
            Shinelogics Infotech Pvt. Ltd, 3rd Floor, KJ Aditya Towers #L-14,
            Vikaram Sarabhai Instronic Estate Phase II, (SRP Tools),
            Thiruvanmiyur, Chennai – 600 042. +91 -9445033734 +91 -4422431428
            info@shinelogics.com Time: Mon - Sat (9:00 - 18:00) POLICIES
            Profanity Policy
          </p>

          <p>
            Farm2Bag prohibits the use of language that is racist, hateful,
            sexual or obscene in nature in a public area. This policy extends to
            text within listings, on Seller pages and all other areas of the
            site that another User may view. If the profane words are part of a
            title for the item being sold, we allow Sellers to 'blur' out the
            bulk of the offending word with asterisks (i.e., s*** or f***).
          </p>

          <p>
            Please report any violations of this policy to the correct area for
            review: • Report offensive Display Names • Report offensive language
            in a listing or otherwise
          </p>

          <p>
            If a feedback comment; or any communication made between Users on
            the Platform; or email communication between Users in relation to
            transactions conducted on Platform contain profanity, please review
            Our feedback removal policy and submit a request for action/removal.
          </p>
          <p>
            Disciplinary action may result in the indefinite suspension of a
            User's account, temporary suspension, or a formal warning.
          </p>
          <p>
            Farm2Bag will consider the circumstances of an alleged policy
            violation and the user's trading records before taking action.
          </p>
          <p>
            Violations of this policy may result in a range of actions,
            including: 1. Limits placed on account privileges; 2. Loss of
            special status; 3. Account suspension.
          </p>
          <p>
            Farm2Bag shall have the right to delete a product review posted by
            the customer at its sole discretion, if it is of the opinion that
            the review contains offensive language as stated above. Further, if
            Farm2Bag is of the opinion that the review unfairly either: (i)
            causes disadvantage to a product; or (ii) increases the popularity
            of the product, Farm2Bag shall have the right to delete the customer
            review. Farm2Bag shall also, at its sole discretion have the right
            to blacklist the customer from posting any further customer reviews.
          </p>
          <h4>Replacement Guarantee*</h4>
          <p>
            The Replacement Guarantee seeks to assist Buyers who have been
            defrauded by qualified sellers on the Platform. If at the time of
            delivery and/or within specified days from the date of delivery of
            the product/s, if any defect is found, then the buyer of the
            product/s can ask for replacement of the product/s from the seller.
          </p>
          <p>
            If Farm2Bag has suspicion or knowledge, that any of its buyers and
            sellers are involved in any activity that is intended to provide
            claims or information that is false, misleading or not genuine, then
            Farm2Bag may while reserving its rights to initiate civil and/or
            criminal proceedings against User may also at its sole discretion
            suspend, block, restrict, cancel the Display Name of such buyer and
            seller and /or disqualify that User and any related Users from
            availing protection through this program.
          </p>
          <p>
            Farm2Bag reserves its right to initiate civil and/or criminal
            proceedings against a User who, files a invalid and/or false claims
            or provides false, incomplete, or misleading information. In
            addition to the legal proceedings as aforesaid, Farm2Bag may at its
            sole discretion suspend, block, restrict, cancel the Display Name
            [and its related Display Names] of such User and/or disqualify that
            User and any related Users from availing protection through this
            program. Any person who, knowingly and with intent to injure,
            defraud or deceive, files a Fraudulent Complaint containing false,
            incomplete, or misleading information may be guilty of a criminal
            offence and will be prosecuted to the fullest extent of the law.
          </p>
          <p>
            For more details related to Replacement Policy, refer to
            s/help/cancellation-returns
          </p>
          <h4>Returns Policy</h4>
          <p>
            Definition: 'Return' is defined as the action of giving back the
            item purchased by the Buyer to the Seller on the Farm2Bag Platform.
            Following situations may arise:
          </p>

          <p>
            1. Item was defective 2. Item was damaged during the Shipping 3.
            Products was / were missing 4. Wrong item was sent by the Seller.
          </p>
          <p>
            Return could also result in refund of money in most of the cases
          </p>
          <p>Points to be noted:</p>
          <p>
            1. Seller can always accept the return irrespective of the policy.
          </p>
          <p>
            2. If Seller disagrees a return request, Buyer can file a dispute
            under the Buyer Protection Program*.
          </p>
          <p>
            We encourage the Buyer to review the listing before making the
            purchase decision. In case Buyer orders a wrong item, Buyer shall
            not be entitled to any return/refund.
          </p>
          <p>
            Buyer needs to raise the return request within the return period
            applicable to the respective product. Once Buyer has raised a return
            request by contacting Us, Seller while closing the return ticket can
            select one of the following:
          </p>
          <p>
            1. Replace after shipment collection - Seller has agreed to wait for
            the logistics team to collect the shipment from the buyer before
            replacing it)
          </p>
          <p>
            2. Refund after shipment collection - Seller has agreed to wait for
            the logistics team to collect the shipment from the buyer before
            refunding)
          </p>

          <p>
            3. On certain select days as specified by Farm2Bag (such as 'The Big
            Billion Day') separate policies may be applicable.
          </p>
          <p>
            In the event the Seller accepts the return request raised by the
            Buyer, Buyer will have to return the product and then the refund
            shall be credited to the Buyers account.
          </p>
          <p>
            In case the Seller doesn't close the ticket in 7 days from the date
            of intimation to the Seller about the refund request, the refund
            request shall be settled in favor of the Buyer.
          </p>
          <p>
            Further for returns being made by Buyer to the Seller of the
            product, the following parameters needs to be ensured by the Buyer:
          </p>
          <p>Product should be in same kind of original packaging</p>
          <p>
            If the product being returned is not in accordance with the above
            parameters, then Buyer shall not be entitled to any refund of money
            from the Seller.
          </p>
          <p>
            Shipping cost for returning the product shall be borne and incurred
            by the Seller.
          </p>
          <h4>Replacement</h4>
          <p>
            Definition: Replacement is the action or process of replacing
            something in place of another. A Buyer can request for replacement
            whenever he is not happy with the item, reason being Damaged in
            shipping, Defective item, Item(s) missing, wrong item shipped and
            the like.
          </p>
          <p>Points to be noted:</p>
          <p>
            1. Seller can always accept the return irrespective of the policy.
          </p>
          <p>
            2. If Seller disagrees for a return request, Buyer can file a
            dispute under Buyer Protection Program*.
          </p>
          <p>
            Buyer needs to raise the replacement request within the return
            period applicable to the respective product. Once Buyer has raised a
            replacement request by contacting Us on the Toll Free Number
            provided on the Platform. Once the replacement request has been
            raised, the following steps shall be followed:
          </p>
          <p>
            1. Buyer is asked for "Reason for Return". Among others, the
            following are the leading reasons:
          </p>
          <p>2. Shipping was damaged</p>
          <p>3. Item was defective</p>
          <p>4. Item Dead on Arrival</p>
          <p>5. Item(s) were missing</p>
          <p>6. Wrong item sent</p>
          <p>
            7. An intimation shall be provided to Seller seeking either
            "approval" or "rejection" of the replacement request.
          </p>
          <p>
            8. In case the Seller accepts the replacement request, Buyer shall
            be required to return the product to the Seller and only after
            return of the product, Seller shall be obliged to provide the
            replacement product to the Buyer.
          </p>
          <p>
            9. Incase Seller rejects the replacement request, Buyer can choose
            to raise a dispute by writing to info@Farm2Bag.com.
          </p>
          <p>
            In case the Seller doesn't have the product at all, Seller can
            provide the refund to the Buyer and Buyer shall be obligated to
            accept the refund in lieu of replacement. All the product parameters
            shall be required to be complied with in cases of replacement.
          </p>
          <p>
            If the Seller doesn't respond to the Buyer's replacement request,
            within three (3) days from the date of replacement request placed by
            the Buyer, refund shall be processed in favour of Buyer and Seller
            shall be liable to refund amount paid to the Seller.
          </p>
          <p>
            All shipping and other replacement charges shall be borne and
            incurred by the Seller.
          </p>
          <h4>Disputes (Resolutions) Policy</h4>
          <h4>Overview</h4>
          <p>
            Generally, transactions are conducted smoothly on Farm2Bag. However
            there may be some cases where both the Buyers and Sellers may face
            issues. At Farm2Bag, we have a Dispute Resolution process in order
            to resolve disputes between Buyers and Sellers.
          </p>
          <p>What is a 'dispute'?</p>
          <p>
            A 'Dispute' can be defined as a disagreement between a Buyer and a
            Seller in connection with a transaction on the Platform.
          </p>
          <p>How does a 'dispute' occur in the Marketplace?</p>
          <p>
            Disputes are filed as a result of a disagreement between the Buyer
            and the Seller. Disputes arise out of an issue that is raised by
            either party not being completely satisfied with the resolution of
            their complaint/issue.
          </p>
          <p>
            It is important that before a Buyer/Seller raises a dispute, they
            should attempt to solve the issue. Please note that whenever a Buyer
            raises a dispute, the Seller's payment for that order is put on hold
            immediately until the issue is resolved.
          </p>
          <p>How is a 'dispute' created?</p>
          <p>
            Whenever there is a disagreement, the Buyer can write to
            dispute@Farm2Bag.com, while the Seller can write to ss@Farm2Bag.com,
            in order to raise a dispute. Disputes can be raised at a particular
            transaction level.
          </p>
          <p>What are the various types of 'disputes'?</p>
          <p>
            Following are the indicative examples of potential disputes: 1.
            Wrong item received 2. Item Not as described 3. Damaged or Seal
            broken on Product 4. Part/Accessory missing 5. Item not Compatible
            6. Seller Description/Specification Wrong 7. Defective (Functional
            issues) 8. Product not working and Manufacturer claims invalid
            Invoice{" "}
          </p>
          <p>
            In case the Seller rejects the return request of the Buyer, and
            Buyer raises a dispute, then Farm2Bag will try to mediate and
            resolve the dispute between both the parties. If the dispute is
            resolved in favour of the Buyer, a refund is provided once the
            product is returned to the Seller. If the dispute is settled in
            favour of the Seller, Buyer is entitled to any refund.
          </p>
          <h4>Buyer Protection Program</h4>
          <p>
            In case of a dispute where the Seller is unable to provide a refund
            or a replacement, Farm2Bag will actively work towards reaching a
            resolution.
          </p>
          <p>
            The Buyer Protection Program covers Buyers who are unable to
            successfully resolve their dispute with the Seller or are not
            satisfied the resolution provided by the Seller.
          </p>
          <p>
            The Buyer can write to resolution@Farm2Bag.com if the issue with the
            Seller is not resolved. Farm2Bag's Customer Support team will look
            into the case to check for possible fraud and if the Buyer has been
            blacklisted/blocked from making purchases on the Platform. Only
            after verifying these facts, a dispute can be registered.
          </p>
          <p>
            In due course of resolution, Farm2Bag's Customer Support Team will
            facilitate a conference call including the Seller and the Buyer.
          </p>
          <p>
            When a dispute has been raised, Farm2Bag may provide both the
            parties access to each others Display Names, contact details
            including email addresses and other details pertaining to the
            dispute. Buyers and Sellers are subject to final consent from
            Farm2Bag for settling the dispute.
          </p>
          <p>Buyer Eligibility and Restrictions</p>
          <p>
            Only the Buyers who have purchased the product on Farm2Bag are
            eligible for the Buyer Protection Program.
          </p>
          <p>
            Buyers can file a dispute within 45 days from the date of delivery
            of the product
          </p>
          <p>
            Any damage or loss to the product after delivery will not be covered
            under this program and will completely be the Buyer's
            responsibility. Buyers should refuse to accept delivery if the item
            is damaged.
          </p>
          <p>
            To be able to take advantage of the Buyer Protection Program, Buyers
            should first contact the Seller and attempt to resolve the issue. If
            the Buyer doesn't hear from the Seller or is unable to resolve the
            issue with the Seller even after contact, a dispute can be raised
            with Farm2Bag by writing an email to resolution@Farm2Bag.com
          </p>
          <p>
            Fraudulent charges and claims are not covered under Buyer Protection
            Program
          </p>
          <p>
            If the Buyer has already initiated chargeback through the credit
            card issuing bank, it will not be covered under Buyer Protection
            Program, though in such cases a Seller can file a claim through the
            Seller Protection Program.
          </p>
          <p>
            Blacklisted and Blocked Buyers are not covered by the Buyer
            Protection Program.
          </p>
          <p>
            Buyers who have reached their maximum lifetime limit for claims are
            also not eligible. Buyers can make a maximum of 5 claims per year on
            Farm2Bag. If the claim was withdrawn, it is not counted. The
            coverage amount will be limited to â‚¹50,000
          </p>
          <p>
            Through the Buyer Protection program, Farm2Bag does not provide any
            guarantee/warranty to Buyers for products sold on Farm2Bag against
            technical/manufacturing defects.
          </p>
          <p>
            Raising disputes against Sellers does not automatically entitle the
            Buyer to a refund or replacement for the product purchased. Farm2Bag
            shall verify the disputes so raised and may process only such claims
            that are valid and genuine.
          </p>
          <p>
            Farm2Bag shall at no point be responsible for any direct or indirect
            losses, expenses, costs of any nature whatsoever that may be
            incurred by any Buyer/Seller.
          </p>
          <p>
            Claims of the nature of 'Buyer remorse' (i.e. instances where
            products are bought by the Buyer by mistake or where the Buyer
            chooses to change his/her mind with regard to the product purchased
            by him) will not be entertained through this program.
          </p>
          <p>
            Farm2Bag reserves its right to initiate civil and/or criminal
            proceedings against a User who, files an invalid and/or false claims
            or provides false, incomplete, or misleading information. In
            addition to the legal proceedings as aforesaid, Farm2Bag may at its
            sole discretion suspend, block, restrict, cancel the Display Name
            [and its related Display Names] of such User and/or disqualify that
            user and any related users from availing protection through this
            program.
          </p>
          <p>
            Decisions made by Farm2Bag under the Buyer Protection Program shall
            be final and binding on its Users.
          </p>
          <p>
            Farm2Bag reserves the right to modify / discontinue Buyer Protection
            Program without any prior notice period to its Users.
          </p>
          <p>
            Through this program, Farm2Bag shall not entertain claims of Buyers
            who have incurred loss due to delayed shipment or delivery of the
            item by the Seller.
          </p>
          <p>
            Farm2Bag Customer Support Team may seek additional information /
            clarification from Buyer to facilitate resolution of the dispute. In
            the event Buyer does not respond with information / clarification
            sought within 10 days of such request, the dispute shall be
            auto-closed in favour of the Seller. Disputes via Chargeback
          </p>
          <p>
            Whenever a chargeback (CB) comes from a payment gateway/bank,
            following situations may arise:
          </p>
          <p>
            1. Item not received CB - Buyer hasn't received the item. Refund
            will be created in accordance with the dispute policies
          </p>
          <p>
            2. Unauthorized CB - Buyer hasn't made this particular transaction.
            Refund will be created in accordance with the dispute policies.
          </p>
          <p>
            Seller expressly agrees that issuing the correct and complete
            invoice is the sole and primary responsibility of the Seller.
            Furthermore, Seller shall ensure that invoices state "Powered by
            Farm2Bag" and failing to do so Seller will be liable for chargebacks
            (as applicable).
          </p>
          <p>
            1. Item not as described - meaning item is not what Buyer expected.
            Dispute will be decided in accordance with the dispute policies.
          </p>
          <p>Email Abuse & Threat Policy</p>
          <p>
            Private communication, including email correspondence, is not
            regulated by Farm2Bag. Farm2Bag encourages its Users to be
            professional, courteous and respectful when communicating by email.
          </p>
          <p>
            However, Farm2Bag will investigate and can take action on certain
            types of unwanted emails that violate Farm2Bag policies.
          </p>
          <p>Such instances:</p>
          <p>
            Threats of Bodily Harm - Farm2Bag does not permit Users to send
            explicit threats of bodily harm.
          </p>
          <p>
            Misuse of Farm2Bag System - Farm2Bag allows Users to facilitate
            transactions through the Farm2Bag system, but will investigate any
            misuse of this service.
          </p>
          <p>
            Spoof (Fake) email - Farm2Bag will never ask you to provide
            sensitive information through email. In case you receive any spoof
            (fake) email, you are requested to report the same to Us through
            'Contact Us' tab.
          </p>
          <p>
            Spam (Unsolicited Commercial email) - Farm2Bag's spam policy applies
            only to unsolicited commercial messages sent by Farm2Bag Users.
            Farm2Bag Users are not allowed to send spam messages to other Users.
          </p>
          <p>
            Offers to Buy or Sell Outside of Farm2Bag - Farm2Bag prohibits email
            offers to buy or sell listed products outside of the Farm2Bag
            Platform. Offers of this nature are a potential fraud risk for both
            Buyers and Sellers.
          </p>
          <p>
            Farm2Bag policy prohibits user-to-user threats of physical harm via
            any method including, phone, email and on Our public message boards.
          </p>
          <p>
            Violations of this policy may result in a range of actions,
            including:
          </p>
          <p>
            • Limits on account privileges • Account suspension • Cancellation
            of listings • Loss of special status{" "}
          </p>
          <p>Other Businesses</p>
          <p>
            Farm2Bag does not take responsibility or liability for the actions,
            products, content and services on the Platform, which are linked to
            Affiliates and / or third party Platforms using Platform's APIs or
            otherwise. In addition, the Platform may provide links to the third
            party Platforms of Our affiliated companies and certain other
            businesses for which, Farm2Bag assumes no responsibility for
            examining or evaluating the products and services offered by them.
            Farm2Bag do not warrant the offerings of, any of these businesses or
            individuals or the content of such third party Platform(s). Farm2Bag
            does not endorse, in any way, any third party Platform(s) or content
            thereof.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Terms;
